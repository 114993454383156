<template>
  <v-card flat class="ma-0" style="height: 100%">
    <v-card-text style="height: 100%">
      <div class="d-sm-flex align-center flex-column" style="height: 100%">
        <div class="d-block w-100">
          <h3 class="title font-weight-regular">Overview Today</h3>
          <h6 class="subtitle-1 font-weight-light mt-2 font-weight-black">
            Tổng lượt khám:
          </h6>
          <v-sheet class="d-block w-100 pa-2 mt-1 text-center" color="#007bff">
            <span class="text-h6 white--text font-weight-bold">{{ totalVisitation }}</span>
          </v-sheet>
        </div>

        <template v-if="loading">
          <div class="d-flex justify-center align-center" style="height: 100%">
            <v-progress-circular
              size="40"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </template>
        <template v-else>
          <div class="d-flex flex-column w-100 mt-4">
            <!-- In process -->
            <v-row justify="space-between">
              <v-col xl="6">
                <v-chip label small color="#ffc107" class="pa-4 w-100">
                  <span class="text-subtitle-1 white--text font-weight-bold">In process</span>
                </v-chip>
              </v-col>
              <v-col xl="5" class="text-center">
                <span class="ml-2 text-center text-subtitle-1 font-weight-bold" style="width: 100%">
                  {{ totalInProcessing }}
                </span>
              </v-col>
            </v-row>
          
            <!-- FINISH -->
            <v-row justify="space-between">
              <v-col xl="6">
                <v-chip label small color="#51c582" class="pa-4 w-100">
                  <span class="text-subtitle-1 white--text font-weight-bold">Finish</span>
                </v-chip>
              </v-col>
              <v-col xl="5" class="text-center">
                <span class="ml-2 text-center text-subtitle-1 font-weight-bold" style="width: 100%">
                  {{ totalFinish }}
                </span>
              </v-col>
            </v-row>

            <!-- PAID -->
            <v-row justify="space-between">
              <v-col xl="6">
                <v-chip label small color="#00bcd4" class="pa-4 w-100">
                  <span class="text-subtitle-1 white--text font-weight-bold">Paid</span>
                </v-chip>
              </v-col>
              <v-col xl="5" class="text-center">
                <span class="ml-2 text-center text-subtitle-1 font-weight-bold" style="width: 100%">
                  {{ totalPaid }}
                </span>
              </v-col>
            </v-row>
          </div>

          <div class="d-flex flex-column align-center w-100 mt-5">
            <span class="text-subtitle-1 w-100">Chưa xuất EHoaDon: <b class="red--text">{{ totalNotExportInvoice }}</b></span>
            <a href="/invoice/create-batch-invoice" class="primary--text w-100 mt-1" v-if="totalNotExportInvoice > 0">Export now</a>
            <template v-if="error">
              <span class="red--text mt-1">{{ error_messages }}</span>
            </template>
          </div>
        </template>
        
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import ReportBusiness from "@/services/report"
export default {

  data: function() { 
    return {
      // localVideo: null,
      // mediaStreamConstraints: {
      //   video: true
      //}
      totalVisitation: 0,
      totalInProcessing: 0,
      totalFinish: 0,
      totalPaid: 0,
      totalNotExportInvoice: 0,

      loading: false,
      error: false,
      error_messages: ''
    }
  },
  mounted() {
    this.getOverView()
  },

  methods: {
    // initVideo() {
    //   navigator.mediaDevices.getUserMedia(this.mediaStreamConstraints)
    //     .then(this.gotLocalMediaStream).catch(this.handleLocalMediaStreamError);
    // },
    // gotLocalMediaStream(mediaStream) {
    //   this.localVideo = mediaStream;
    //   this.$refs.video.srcObject  = mediaStream;
    // },
    // handleLocalMediaStreamError(err) {
    //   console.err(err)
    // },
    async getOverView() {
      this.loading = true
      var overview = await ReportBusiness.getOverviewToday();
      if (overview.error) {
        this.loading = false
        this.error_messages = 'Error when try to get data!';
        return
      }

      this.loading = false;
      this.totalVisitation = overview.totalVisitation;
      this.totalInProcessing = overview.totalInProcessing;
      this.totalFinish = overview.totalFinish;
      this.totalPaid = overview.totalPaid;
      this.totalNotExportInvoice = overview.totalNotExportInvoice;
    }
  }
}
</script>

<style>

</style>